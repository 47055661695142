var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "작업결과 상세" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              editable: false,
                              label: "작업일",
                              name: "workDt",
                            },
                            model: {
                              value: _vm.data.workDt,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workDt", $$v)
                              },
                              expression: "data.workDt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && !_vm.disabled,
                              label: "작업내역",
                              name: "workContent",
                            },
                            model: {
                              value: _vm.data.workContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workContent", $$v)
                              },
                              expression: "data.workContent",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              isArray: false,
                              editable: _vm.editable && !_vm.disabled,
                              codeGroupCd: "SOP_WORK_TYPE_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "작업허가서 구분",
                              name: "workpermitTypes",
                            },
                            model: {
                              value: _vm.data.workpermitTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workpermitTypes", $$v)
                              },
                              expression: "data.workpermitTypes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              afterIcon:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.data.permitNo
                                  ? [
                                      {
                                        name: "open_in_new",
                                        click: true,
                                        callbackName: "openWorkPermit",
                                        color: "green",
                                        tooltip: "작업허가서 상세",
                                      },
                                      {
                                        name: "add",
                                        click: true,
                                        callbackName: "addWorkPermit",
                                        color: "blue",
                                        tooltip: "작업허가서 발행",
                                      },
                                      {
                                        name: "search",
                                        click: true,
                                        callbackName: "searchWorkPermit",
                                        color: "teal",
                                        tooltip: "작업허가서 검색",
                                      },
                                      {
                                        name: "close",
                                        click: true,
                                        callbackName: "removeWorkPermit",
                                        color: "red",
                                        tooltip: "초기화",
                                      },
                                    ]
                                  : _vm.editable &&
                                    !_vm.disabled &&
                                    !_vm.data.permitNo
                                  ? [
                                      {
                                        name: "add",
                                        click: true,
                                        callbackName: "addWorkPermit",
                                        color: "blue",
                                        tooltip: "작업허가서 발행",
                                      },
                                      {
                                        name: "search",
                                        click: true,
                                        callbackName: "searchWorkPermit",
                                        color: "teal",
                                        tooltip: "작업허가서 검색",
                                      },
                                      {
                                        name: "close",
                                        click: true,
                                        callbackName: "removeWorkPermit",
                                        color: "red",
                                        tooltip: "초기화",
                                      },
                                    ]
                                  : _vm.data.permitNo
                                  ? [
                                      {
                                        name: "open_in_new",
                                        click: true,
                                        callbackName: "openWorkPermit",
                                        color: "green",
                                        tooltip: "작업허가서 상세",
                                      },
                                    ]
                                  : null,
                              editable: _vm.editable && !_vm.disabled,
                              disabled: true,
                              label: "작업허가번호",
                              name: "permitNo",
                            },
                            on: {
                              openWorkPermit: _vm.openWorkPermit,
                              removeWorkPermit: _vm.removeWorkPermit,
                              searchWorkPermit: _vm.searchWorkPermit,
                              addWorkPermit: _vm.addWorkPermit,
                            },
                            model: {
                              value: _vm.data.permitNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "permitNo", $$v)
                              },
                              expression: "data.permitNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              isArray: false,
                              editable: _vm.editable && !_vm.disabled,
                              codeGroupCd: "WO_RISK_FACTOR",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "위험요인",
                              name: "workRiskFactors",
                            },
                            model: {
                              value: _vm.data.workRiskFactors,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "workRiskFactors", $$v)
                              },
                              expression: "data.workRiskFactors",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.data.workOprTypeCd !== "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-8 col-sm-8 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-vendor", {
                                attrs: {
                                  editable: _vm.editable && !_vm.disabled,
                                  label: "협력업체",
                                  name: "vendorCd",
                                },
                                model: {
                                  value: _vm.data.vendorCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "vendorCd", $$v)
                                  },
                                  expression: "data.vendorCd",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.data.workOprTypeCd !== "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable: _vm.editable && !_vm.disabled,
                                  label: "작업인원",
                                  name: "vendorWorkerCnt",
                                  type: "number",
                                  suffix: "명",
                                },
                                model: {
                                  value: _vm.data.vendorWorkerCnt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "vendorWorkerCnt", $$v)
                                  },
                                  expression: "data.vendorWorkerCnt",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.data.workOprTypeCd === "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-dept", {
                                attrs: {
                                  type: "edit",
                                  editable: _vm.editable && !_vm.disabled,
                                  label: "작업부서",
                                  name: "workDeptCd",
                                },
                                model: {
                                  value: _vm.data.workDeptCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "workDeptCd", $$v)
                                  },
                                  expression: "data.workDeptCd",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.data.workOprTypeCd === "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-multi-field", {
                                attrs: {
                                  editable: _vm.editable && !_vm.disabled,
                                  userInfo: _vm.userInfo,
                                  isArray: "",
                                  type: "dept_user",
                                  label: "내부작업자",
                                  name: "workUserList",
                                },
                                on: {
                                  "update:userInfo": function ($event) {
                                    _vm.userInfo = $event
                                  },
                                  "update:user-info": function ($event) {
                                    _vm.userInfo = $event
                                  },
                                },
                                model: {
                                  value: _vm.data.workUserList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "workUserList", $$v)
                                  },
                                  expression: "data.workUserList",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        _c("div", { staticClass: "popup-bottom-bar" }, [
          _c(
            "div",
            { staticClass: "popup-bottom-bar-btngroup" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.data.workEndFlag == "WDRC000010"
                    ? _c("q-chip", {
                        attrs: {
                          color: "blue",
                          "text-color": "white",
                          icon: "task_alt",
                          label: "작업완료",
                        },
                      })
                    : _vm._e(),
                  _vm.data.workEndFlag == "WDRC000009"
                    ? _c("q-chip", {
                        attrs: {
                          label: "작업취소",
                          "text-color": "white",
                          icon: "cancel",
                          color: "red",
                        },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled && _vm.ended
                    ? _c("c-btn", {
                        attrs: {
                          color: "blue",
                          icon: "task_alt",
                          label: "작업완료",
                        },
                        on: { btnClicked: _vm.saveEnd },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled && _vm.ended
                    ? _c("c-btn", {
                        attrs: {
                          label: "작업취소",
                          icon: "cancel",
                          color: "red",
                        },
                        on: { btnClicked: _vm.saveCancel },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          label: "작업삭제",
                          icon: "delete",
                          color: "red",
                        },
                        on: { btnClicked: _vm.saveRemove },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.data,
                          mappingType: _vm.mappingType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveData,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "popup-bottom-bar-close" },
            [
              _c("q-btn", {
                attrs: { flat: "", color: "gray", icon: "arrow_back" },
                on: { click: _vm.closePopUps },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }