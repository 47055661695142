<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card class="cardClassDetailForm" title="작업결과 상세">
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :required="true"
                  :editable="false"
                  label="작업일"
                  name="workDt"
                  v-model="data.workDt"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled"
                  label="작업내역"
                  name="workContent"
                  v-model="data.workContent">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <c-checkbox
                  :isArray="false"
                  :editable="editable&&!disabled"
                  codeGroupCd="SOP_WORK_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="작업허가서 구분"
                  name="workpermitTypes"
                  v-model="data.workpermitTypes"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :afterIcon="editable&&!disabled&&data.permitNo ? [
                      {
                        name: 'open_in_new',
                        click: true,
                        callbackName: 'openWorkPermit',
                        color: 'green',
                        tooltip: '작업허가서 상세',
                      },
                      {
                        name: 'add',
                        click: true,
                        callbackName: 'addWorkPermit',
                        color: 'blue',
                        tooltip: '작업허가서 발행',
                      },
                      {
                        name: 'search',
                        click: true,
                        callbackName: 'searchWorkPermit',
                        color: 'teal',
                        tooltip: '작업허가서 검색',
                      },
                      {
                        name: 'close',
                        click: true,
                        callbackName: 'removeWorkPermit',
                        color: 'red',
                        tooltip: '초기화',
                      },
                    ] : editable&&!disabled&&!data.permitNo ? [
                      {
                        name: 'add',
                        click: true,
                        callbackName: 'addWorkPermit',
                        color: 'blue',
                        tooltip: '작업허가서 발행',
                      },
                      {
                        name: 'search',
                        click: true,
                        callbackName: 'searchWorkPermit',
                        color: 'teal',
                        tooltip: '작업허가서 검색',
                      },
                      {
                        name: 'close',
                        click: true,
                        callbackName: 'removeWorkPermit',
                        color: 'red',
                        tooltip: '초기화',
                      },
                    ] : data.permitNo ?  [
                      {
                        name: 'open_in_new',
                        click: true,
                        callbackName: 'openWorkPermit',
                        color: 'green',
                        tooltip: '작업허가서 상세',
                      },
                    ]: null
                  "
                  :editable="editable&&!disabled"
                  :disabled="true"
                  label="작업허가번호"
                  name="permitNo"
                  v-model="data.permitNo"
                  @openWorkPermit="openWorkPermit"
                  @removeWorkPermit="removeWorkPermit"
                  @searchWorkPermit="searchWorkPermit"
                  @addWorkPermit="addWorkPermit"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <c-checkbox
                  :isArray="false"
                  :editable="editable&&!disabled"
                  codeGroupCd="WO_RISK_FACTOR"
                  itemText="codeName"
                  itemValue="code"
                  label="위험요인"
                  name="workRiskFactors"
                  v-model="data.workRiskFactors"
                />
              </div>
              <div class="col-xs-8 col-sm-8 col-md-3 col-lg-3 col-xl-3" v-if="data.workOprTypeCd !== 'WOTC000001'">
                <c-vendor
                  :editable="editable&&!disabled"
                  label="협력업체"
                  name="vendorCd"
                  v-model="data.vendorCd" />
              </div>
              <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3" v-if="data.workOprTypeCd !== 'WOTC000001'">
                <c-text
                  :editable="editable&&!disabled"
                  label="작업인원"
                  name="vendorWorkerCnt"
                  type="number"
                  suffix="명"
                  v-model="data.vendorWorkerCnt">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="data.workOprTypeCd === 'WOTC000001'">
                <c-dept type="edit" :editable="editable&&!disabled" label="작업부서" name="workDeptCd" v-model="data.workDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="data.workOprTypeCd === 'WOTC000001'">
                <c-multi-field 
                  :editable="editable&&!disabled"
                  :userInfo.sync="userInfo"
                  isArray
                  type="dept_user"
                  label="내부작업자" 
                  name="workUserList" 
                  v-model="data.workUserList" />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
      <div class="popup-bottom-bar">
        <div class="popup-bottom-bar-btngroup">
          <q-btn-group outline >
            <q-chip
              v-if="data.workEndFlag == 'WDRC000010'"
              color="blue"
              text-color="white"
              icon="task_alt"

              label="작업완료" />
            <q-chip
              v-if="data.workEndFlag == 'WDRC000009'"
              label="작업취소"
              text-color="white"
              icon="cancel"
              color="red" />
            <c-btn
              v-if="editable&&!disabled&&ended"
              color="blue"
              icon="task_alt"
              label="작업완료"
              @btnClicked="saveEnd" />
            <c-btn
              v-if="editable&&!disabled&&ended"
              label="작업취소"
              icon="cancel"
              color="red"
              @btnClicked="saveCancel" />
            <c-btn
              v-if="editable&&!disabled"
              label="작업삭제"
              icon="delete"
              color="red"
              @btnClicked="saveRemove" />
            <c-btn
              v-if="editable&&!disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </div>
        <div class="popup-bottom-bar-close">
          <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-daily-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        workPlanId: '',
        woRequestId: '',
        workDt: '',
        plantCd: null,
        workResultDailyId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      data: {
        workResultDailyId: '',  // 일일작업결과 일련번호
        workResultId: '',  // 작업결과 일련번호
        workResultWorkId: '',  // 작업결과 작업계획일련번호
        workDt: '',  // 작업일
        workContent: '',  // 작업내용
        workpermitTypes: '',  // 작업허가서 종류
        permitNo: '',
        permitTypeCd: '',
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        vendorCd: '',  // 협력업체
        vendorWorkerCnt: '',  // 협력업체 작업인원
        workEndFlag: '',  // 작업완료여부
        workDeptCd: '',
        workUserList: [],
      },
      editable: false,
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      saveUrl: transactionConfig.wod.workorder.daily.insert.url,
      mappingType: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    userInfo() {
      return {
        userItems: this.data.workUsers,
        userText: 'workUserName',
        userValue: 'workUserId',
        deptText: 'workDeptName',
        deptValue: 'workDeptCd',
      }
    },
    disabled() {
      return this.data.workEndFlag !== 'WDRC000001' && this.data.workEndFlag !== 'WDRC000002';
    },
    ended() {
      return this.data.workEndFlag === 'WDRC000002';
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.wod.workorder.daily.get.url;
      this.insertUrl = transactionConfig.wod.workorder.daily.insert.url;
      this.updateUrl = transactionConfig.wod.workorder.daily.update.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.workResultWorkId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {workResultWorkId: this.popupParam.workResultWorkId, workDt: this.popupParam.workDt, workResultDailyId: this.popupParam.workResultDailyId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
        },);
      }
    },
    saveEnd() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업을 완료하시겠습니까?\r\n완료된 작업은 수정할 수 없습니다.',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.workEndFlag = 'WDRC000010';
          this.$http.url = this.updateUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.data;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCancel() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업을 취소하시겠습니까?\r\n취소된 작업은 수정할 수 없습니다.',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.workEndFlag = 'WDRC000009';
          this.$http.url = this.updateUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.data;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveRemove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '해당일자의 작업을 삭제하시겠습니까?\r\n삭제된 작업은 작업결과를 입력할 수 없습니다.',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.workEndFlag = 'WDRC999999';
          this.$http.url = this.updateUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.data;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveData(_workEndFlag, _msg) {
      if (this.popupParam.workResultDailyId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: _msg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.data.workEndFlag = 'WDRC000002';
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.workResultDailyId = _result.data.workResultDailyId;
      this.getDetail();
    },
    // 작업허가서 선택
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
        plantCd: this.popupParam.plantCd, 
        popupMode: 'Y',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.length > 0) {
        this.data.permitNo = data[0].permitNo;
        this.data.sopWorkPermitId = data[0].sopWorkPermitId;
        this.data.permitTypeCd = data[0].permitTypeCd;
      }
    },
    removeWorkPermit() {
      this.data.permitNo = '';
      this.data.sopWorkPermitId = '';
      this.data.permitTypeCd = '';
    },
    addWorkPermit() {
      // 작업허가서
      this.popupOptions.title = '작업허가서 발행';
      this.popupOptions.param = {
        plantCd: this.popupParam.plantCd,
        workResultDailyId: this.popupParam.workResultDailyId,
      };
      this.popupOptions.isFull = true;
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupSwp;
    },
    closePopupSwp() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openWorkPermit() {
      this.popupOptions.title = '작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: this.data.sopWorkPermitId,
        permitTypeCd: this.data.permitTypeCd,
      };
      this.popupOptions.isFull = true;
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupSwp;
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>